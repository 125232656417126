<template>
  <div class="checklist-body">
    <el-table :data="Elements">
      <el-table-column>
        <template #header>
          <b class="table-header-custom-b">{{ CheckListName }}</b>
        </template>

        <template #default="scope">
          <div class="machform-checklist">
            <label for="" class="break-space-word">{{ scope.row.element_title }}</label>
            <template v-if="scope.row.element_type == 'signature'">
              <div>
                <el-image
                  class="border"
                  style="width: 300px; height: 150px"
                  fit="contain"
                  :src="scope.row.Host + scope.row.element_value"
                />
              </div>
            </template>
            <template v-else-if="scope.row.element_type == 'file'">
              <div v-html="scope.row.element_value"></div>
            </template>
            <template v-else>
              <div>{{ scope.row.element_value }}</div>
            </template>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="100" v-if="reject" :label="$t.translate('LBL_NC')">
        <template #default="scope">
          <el-switch
            @change="triggerList"
            v-model="scope.row.IsNC"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from "vue";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";

export default {
  props: {
    data: Object,
    reject: Boolean,
  },
  emits: ["change"],
  setup(props, context) {
    let Elements = ref([]);
    let Form = ref({});

    let triggerList = () => {
      context.emit(
        "change",
        _.map(Elements.value, (r) => {
          return { IsPassed: r.IsNC ? 0 : 1, ElementId: parseInt(r.element_id) };
        })
      );
    };

    let CheckListName = computed(() => {
      return `${Form?.value?.form_name || ""} ${Form?.value?.form_description || ""}`;
    });

    onMounted(async () => {
      let res = await get("/assessment/checklist/submission", {
        MachformEntryKey: props.data.MachformEntryKey,
        MachformId: props.data.MachformId,
      });
      Form.value = res?.Form;
      Elements.value = _.map(res?.Elements, (r) => {
        return { ...r, IsNC: 0, Host: res?.Host };
      });
    });

    return { Elements, CheckListName, triggerList };
  },
};
</script>
<style>
/* .checklist-body .el-table__header-wrapper {
  display: none !important;
} */

.checklist-body .el-table tr,
.checklist-body .el-table th,
.checklist-body .el-table,
.checklist-body .el-table__body-wrapper {
  background: none;
}
.machform-checklist * {
  color: black;
  font-size: 0.85rem;
}

.machform-checklist label {
  margin: 0px;
}
.machform-checklist {
  /* margin-bottom: 10px; */
}
.table-header-custom-b {
  color: #000;
}

.machform-checklist a {
  color: #f1832d;
}
</style>
